@import './custom_colors';

.notes{
    height: calc(100% - 70px);
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
}

.bm-menu{
    background: white;
    padding-top: 10px;
}

a.list-item.is-active{
    background-color: $custom-grey-light;
}
a.list-item.is-active:hover{
    background-color: aqua;
}

.notes-editor{
    padding: 0;
    height: calc(100% - 43px);
    .quill{
        height: 100%;
    }
}