@import "./custom_colors";

nav.navbar {
  height: 70px;
  border-bottom: 1px solid black;
  
  .navbar-brand {  
    img {
      height: 40px;
      margin-top:15px;
    }
  }
}